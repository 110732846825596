import { Badge } from "@mui/material";
import {
  DateCalendar,
  DayCalendarSkeleton,
  PickersDay,
  PickersDayProps,
  esES,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/es";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Confirmation, IWarning, Message } from "../../../components/message";
import Placeholder from "../../../components/placeholder/placeholder";
import { ISpecialist } from "../../../interfaces/specialist/specialist.interface";
import { useAuth } from "../../../middlewares/auth";
import HttpService from "../../../services/http";
import useFetchCalendar from "../hooks/useFetchcalendar";
import "./calendar.css";

dayjs.locale("es");

interface CalendarProps {
  id?: string;
  specialist?: ISpecialist;
  typeAppointment?:number;
}

interface HoursAvailableProps {
  loading: boolean;
  hours: string[] | null;
  id?: string;
  setSelectedHour: React.Dispatch<React.SetStateAction<string | null>>; // Añade setSelectedHour con su tipo correspondiente
}

interface AppointmentType {
  appointment_type_id: number;
  appointments_type_id: { id: number; name: string };
  currency_type: number;
  currencys_type: { id: number; name: string };
  duration: number;
  id: number;
  price: string;
  principal_appointment: number;
  specialist_id: number;
  specialists_id: {
    id: number;
    name: string;
    surname: string;
    card_number: number;
  };
}

interface Appointment {
  created_at: Date;
  end_datetime: Date;
  id: number;
  patient_id: number;
  specialist_appointment_type_id: number;
  specialist_id: number;
  start_datetime: Date;
  updated_at: Date;
}

function HoursAvailable({
  loading,
  hours,
  id,
  setSelectedHour,
}: HoursAvailableProps) {
  const [selectedItem, setSelectedItem] = useState<string>("");

  const convertirRangoHoraAMPM = (rangoHora24: string) => {
    // Dividir el rango de horas en dos horas individuales
    const [horaInicio, horaFin] = rangoHora24.split("-");

    // Parsear las horas con Day.js
    const horaInicioParseada = dayjs(horaInicio, "HH:mm");
    const horaFinParseada = dayjs(horaFin, "HH:mm");

    // Formatear las horas en formato de 12 horas con 'am/pm'
    const horaInicio12 = horaInicioParseada.format("hh:mm A");
    const horaFin12 = horaFinParseada.format("hh:mm A");

    // Retornar el rango de horas en formato de 12 horas
    return `${horaInicio12} - ${horaFin12}`;
  };
  return (
    <Row>
      {loading && <Placeholder />}
      {!loading &&
        hours?.map((item, index) => {
          const handleClick = () => {
            setSelectedHour(item);
            if (selectedItem === item) {
              setSelectedItem(""); // Deselecciona el elemento si ya está seleccionado
            } else {
              setSelectedItem(item); // Selecciona el elemento
            }
          };

          const isSelected = selectedItem === item;
          const spanClassName = isSelected ? "seleted" : "";

          return (
            <Col
              className="item_hours"
              key={index}
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
            >
              <span className={spanClassName} onClick={handleClick}>
                {convertirRangoHoraAMPM(item)}
              </span>
            </Col>
          );
        })}
    </Row>
  );
}

function Calendar({ id, specialist,typeAppointment }: CalendarProps): JSX.Element {
  const navigate = useNavigate();

  const { state } = useAuth();
  const { t } = useTranslation();
  const [selectedHour, setSelectedHour] = useState<string | null>(null);
  const [verifyLogin, setVerifyLogin] = useState<boolean>(false);
  const [daysBlocked, setDaysBlocked] = useState<string[]>([]);
  const [appointmentType, setAppointmentType] = useState<AppointmentType[]>([]);
  const [appointment, setAppointment] = useState<Appointment>();
  const [appointmentTypeselect, setAppointmentTypeselect] =
    useState<AppointmentType | null>();
  const [sending, isSending] = useState<boolean>(false);

  const [isViewLoaded, setIsViewLoaded] = useState<boolean>(false);

  const [warning, setWarning] = useState<IWarning>({
    show: false,
    message: "",
  });

  const {
    excludedDays,
    excludedDaysOfWeek,
    selectedDate,
    setSelectedDate,
    setSelectMonth,
    today,
    availableSlot,
    loading,
    error,
    selectMonth,
    setSelectYear,
  } = useFetchCalendar({ id, withFirstDateAvailable: true });

  useEffect(() => {
    getAppointmentType();
  }, []);

  useEffect(() => {
    setDaysBlocked(excludedDays);
  }, [selectMonth, excludedDays]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (selectedDate != null) return;

      console.info("setTimeout");
      HttpService.get(`first-work-day/${id}`)
        .then((result) => {
          if (
            result?.status &&
            result?.data &&
            Object.keys(result?.data).length > 0
          ) {
            const firstDate =
              Object?.keys(result?.data)?.find(
                (date) => result?.data[date].length > 0
              ) ?? null;
            if (firstDate) setSelectedDate(dayjs(firstDate));
          }
          setIsViewLoaded(true);
          //else initSelectedDateByRange();
        })
        .catch(() => setIsViewLoaded(true));
    }, 1500);
    return () => {
      clearTimeout(handler);
    };
  }, [selectedDate]);

  useEffect(() => {
    const selectedAppointmentTypes = appointmentType.filter(
      (type) => type.principal_appointment === 1
    );
    setAppointmentTypeselect(
      selectedAppointmentTypes.length > 0 ? selectedAppointmentTypes[0] : null
    );
  }, [appointmentType]);

  function shouldDisableDate(valueDate: Dayjs) {
    return (
      excludedDaysOfWeek.includes(valueDate?.day()) ||
      excludedDays.includes(valueDate.format("YYYY-MM-DD"))
    );
  }

  function initSelectedDateByRange() {
    console.info("initSelectedDateByRange");
    let isSelected = false;
    for (let iterator = 1; iterator < 180 && !isSelected; iterator++) {
      if (isSelected) continue;
      const searchDate = today.add(iterator, "day");
      if (!shouldDisableDate(searchDate)) {
        console.info(
          `available date finded ${searchDate.format("DD/MM/YYYY")}`
        );
        isSelected = true;
        setSelectedDate(searchDate);
      }
    }
  }

  const handleMonthChange = (date: Dayjs) => {
    setSelectYear(date.year());
    setSelectMonth(date.month() + 1);
  };

  async function getAppointmentType() {
    await HttpService.post("specialists/getTypeAppointment", {
      specialist_id: id,
    })
      .then((result) => {
        const { status } = result;
        if (status) {
          setAppointmentType(result?.data);
        }
      })
      .catch((e) => {
        setWarning({
          show: true,
          message: e.message,
        });
      });
  }

  const createTemporalAppointment = () => {
    const hourStart = selectedHour?.split("-")[0].split(":")[0];
    const minutesStart = selectedHour?.split("-")[0].split(":")[1];
    const hourEnd = selectedHour?.split("-")[1].split(":")[0];
    const minutesEnd = selectedHour?.split("-")[1].split(":")[1];
    const data = {
      patient_id: null,
      specialist_id: `${id}`,
      specialist_appointment_type_id: `${appointmentTypeselect?.appointment_type_id}`,
      start_datetime: dayjs(selectedDate || new Date())
        .hour(Number(hourStart))
        .minute(Number(minutesStart))
        .second(0)
        .format('YYYY-MM-DD HH:mm:ss'),
      end_datetime: dayjs(selectedDate || new Date())
        .hour(Number(hourEnd))
        .minute(Number(minutesEnd))
        .second(0)
        .format('YYYY-MM-DD HH:mm:ss'),
        appointment_type:typeAppointment
    };
    return HttpService.post("appointments/appointments", data);
  };

  const validateLogin = () => {
    isSending(true);
    createTemporalAppointment()
      .then((result) => {
        const { status, data } = result;
        if (!status) {
          isSending(false);
          setWarning({
            show: true,
            message: data,
          });
          return;
        }
        const newAppointment = result?.data as Appointment;
        setAppointment(newAppointment);
        if (appointmentType && status) {
          if (state?.isLoged) {
            // Agrega una espera de medio segundo antes de navegar
            navigate(`/purchase?id=${id}&hour=${selectedHour}`, {
              preventScrollReset: true,
              state: {
                appointment_type:typeAppointment,
                specialist: specialist,
                requireLogin: false,
                price: appointmentTypeselect?.price,
                currency: appointmentTypeselect?.currencys_type.name,
                appointment: newAppointment,
                selectAppointment: {
                  selectedDate: selectedDate,
                  selectedHour: selectedHour,
                },
              },
              replace: false,
            });
          } else setVerifyLogin(true);
        }
      })
      .catch((e) => {
        setWarning({
          show: true,
          message: e.message,
        });
      });
  };

  function serverDay(
    props: PickersDayProps<Dayjs> & {
      daysBlocked?: string[];
    }
  ) {
    const { daysBlocked, day, outsideCurrentMonth, disabled, ...other } = props;

    const today = dayjs();

    let monthSearch = 0;
    let yearSearch = 0;

    if (daysBlocked && daysBlocked?.length > 0) {
      monthSearch = Number(daysBlocked[0].split("-")[1]);
      yearSearch = Number(daysBlocked[0].split("-")[0]);
    }

    const arrayDates = daysBlocked?.map((date) => Number(date.split("-")[2]));

    if (
      new Date().getMonth() + 1 == Number(monthSearch) &&
      new Date().getFullYear() == yearSearch
    ) {
      for (let i = 1; i <= today.date(); i++) {
        arrayDates?.push(i);
      }
    }

    const isDisabled =
      !props.outsideCurrentMonth &&
      arrayDates &&
      arrayDates.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={
          isDisabled ? (
            <span
              style={{
                marginRight: "2.4em",
                marginTop: "2em",
                color: "darkgray",
                fontSize: "1em",
              }}
            >
              —
            </span>
          ) : undefined
        }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          disabled={isDisabled || disabled}
        />
      </Badge>
    );
  }

  if (error) return <h2>{t("calendarLoadError")}</h2>;

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={esES}
        localeText={{
          previousMonth: `${t("previousMonth")}`,
          nextMonth: `${t("nextMonth")}`,
        }}
      >
        <DateCalendar
          disabled={!isViewLoaded}
          value={selectedDate}
          onChange={(value) => setSelectedDate(value)}
          onMonthChange={handleMonthChange}
          onYearChange={(value) => setSelectYear(value.year())}
          minDate={today}
          shouldDisableDate={shouldDisableDate}
          renderLoading={() => <DayCalendarSkeleton />}
          loading={!isViewLoaded || loading}
          slots={{ day: serverDay }}
          slotProps={{
            day: {
              daysBlocked,
            } as any,
          }}
        />
        {availableSlot && (
          <HoursAvailable
            loading={loading}
            hours={availableSlot}
            id={id}
            setSelectedHour={setSelectedHour}
          />
        )}

        {selectedHour && (
          <Button
            disabled={sending}
            className="btn-reserva"
            onClick={() => validateLogin()}
          >
            {t("bookAppointment")}
          </Button>
        )}

        {verifyLogin && (
          <Confirmation
            hide={() => {
              isSending(false);
              setVerifyLogin(false);
            }}
            show={verifyLogin}
            message={t("loginToContinuePayment")}
            btn={{
              accept: {
                text: `${t("login")}`,
                event: () => {
                  navigate("/login", {
                    preventScrollReset: true,
                    state: {
                      redirect: {
                        url: `/purchase?id=${id}&hour=${selectedHour}`,
                        props: {
                          appointment_type:typeAppointment,
                          specialist: specialist,
                          selectAppointment: {
                            selectedDate: selectedDate,
                            selectedHour: selectedHour,
                          },
                          price: appointmentTypeselect?.price,
                          currency: appointmentTypeselect?.currencys_type.name,
                          appointment,
                          preventScrollReset: true,
                          replace: false,
                        },
                      },
                    },
                    replace: false,
                  });
                },
              },
              cancel: {
                text: `${t("continueWithoutSession")}`,
                event: () => {
                  navigate(`/purchase?id=${id}&hour=${selectedHour}`, {
                    preventScrollReset: true,
                    state: {
                      appointment_type:typeAppointment,
                      specialist: specialist,
                      selectAppointment: {
                        selectedDate: selectedDate,
                        selectedHour: selectedHour,
                      },
                      price: appointmentTypeselect?.price,
                      currency: appointmentTypeselect?.currencys_type.name,
                      appointment,
                      requireLogin: false,
                    },
                    replace: false,
                  });
                },
              },
            }}
          />
        )}
        {warning?.show ? (
          <Message
            show={warning?.show}
            message={warning?.message}
            hide={() => {
              setWarning({ show: false });
            }}
          />
        ) : null}
      </LocalizationProvider>
    </>
  );
}

export default Calendar;
